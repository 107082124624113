import { Component, NgZone } from '@angular/core';
import { ServiceUserLabelService } from './service-user-label.service';

@Component({
  selector: 'app-su-label',
  templateUrl: './service-user-label.component.html',
  styleUrls: ['./service-user-label.component.scss']
})
export class ServiceUserLabelComponent {

  inPageNav = null;

  constructor(
    private readonly serviceUserLabelService: ServiceUserLabelService,
    private readonly zone: NgZone
  ) {

    this.serviceUserLabelService.currentInPageNav.subscribe(inPageNav => {
      this.inPageNav = inPageNav;
      if (inPageNav) {
        this.zone.runOutsideAngular(() => {
          window.document.addEventListener('scroll', this.onWindowScroll);
        });
      } else {
        window.document.removeEventListener('scroll', this.onWindowScroll, false);
      }
    });

  }

  onWindowScroll(event) {

  }

  onSectionButtonClick() {
    const inPageNavContainer = document.getElementById('in-page-nav');
    if (!inPageNavContainer.classList.contains('responsive')) {
      inPageNavContainer.classList.add('responsive');
    } else {
      inPageNavContainer.classList.remove('responsive');
    }
  }

}
