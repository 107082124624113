import { OnInit, Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Utility } from '../../utils/utility';

@Component({
    selector: 'app-list-label',
    template: `
      <span tabindex="0" class="pull-left" title="{{listObjLabel}}">{{listObjLabel}}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLabelComponent implements OnInit {

    @Input('list') list: any[] = [];
    @Input('value') value;
    listObjLabel: String = '';
    constructor() {
    }
    ngOnInit() {
        const obj = Utility.getObjectFromArrayByKeyAndValue(this.list, 'id', this.value);
        if (obj !== null) {
            if (this.propertyDefinedAndNotNull(obj, 'value')) {
                this.listObjLabel = obj['value'];
            } else {
                this.listObjLabel = obj['name'];
            }
        }
    }

    private propertyDefinedAndNotNull(obj: any, property: string): boolean {
        return obj[property] !== undefined && obj[property] !== null;
    }

}